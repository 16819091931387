import Header from './Components/Header.js'
import About from './Components/About.js'
import Who from './Components/Who.js'
import Manage from './Components/Manage.js'
import Charity from './Components/Charity.js'
import Contact from './Components/Contact.js'
import Footer from './Components/Footer.js'

function App() {
  return (
    <div>
      <Header />
      <About />
      <Who />
      <Manage />
      <Charity />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
