export default function About() {
    return (
      <div className="relative bg-gray-50" id="about">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-44 w-full object-cover lg:absolute lg:h-full"
              src={require("../Images/About.jpeg")}
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                About Us
              </h3>
    
              <div className="mt-5 prose prose-indigo text-gray-500">
                <p className="pt-2">
                <b className='text-red-500'>Altaï Partners</b> is registered in France and regulated as a Financial Advisor (Licence No 19007047).
                </p>
                <p className="pt-2">
                  Our offices are located in Nice (France). 
                </p>
                <p className="pt-2">
                Our team is made up of experienced and innovative professionals, who create value through their extensive knowledge of financial markets.
                </p>
                <p className="pt-2">
                  We offer a comprehensive range of investment solutions to Institutional as well as HNWI clients.
                </p>
                <p className="pt-2">
                  Altaï Partners covers a large range of services : fixed income execution, structured products, pre-IPO, financial markets advisory, structuring and support in financial transactions.
   
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
  