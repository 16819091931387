export default function Charity() {
    return (
      <div className="relative bg-gray-50" id="charity">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-44 w-full object-cover lg:absolute lg:h-full"
              src={require("../Images/Charity.jpeg")}
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Charity
              </h3>
              <div className="mt-5 prose prose-indigo text-gray-500">
                <p className="pt-2">
                Altaï Partners is a proud supporter and donor of The Altai Project.
                </p>
                <p className="pt-2">
                The Altai Project is the only international non-profit organization linking nature conservation with sustainable development and cultural heritage and promoting these approaches exclusively in the Greater Altaï region.
                </p>
                <p className="pt-2">
                We share the values of The Altai Project and are thankful for The Altai Project's team efforts to preserve the unique environmental and cultural heritage of the Altaï region.
                </p>
                <p className="pt-2">
                For more information on The Altaï Project: <a className="text-red-500 underline" href="https://www.altaiproject.org/">please click here</a>
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
  