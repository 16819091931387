
  
export default function Example() {
    return (
      <footer className="bg-red-500">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-50">&copy; 2022 Altaï Partners all rights reserved.</p>
          </div>
        </div>
      </footer>
    )
  }
  