
export default function Manage() {
    return (
      <div className="relative bg-red-500" id="manage">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={require("../Images/Manage.png")}
            alt=""
          />
          <div className="absolute inset-0 bg-red-700 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Wealth Management Service</h1>
          <p className="mt-6 text-xl text-indigo-100 max-w-3xl">
          The needs of HNWI, entrepreneurs and family offices are complex at many levels and require the skilled orchestration of expertise provided by our internal and external specialists network.
          It is often the combination of our experience and our access to large institutions and Top Custodian banks and their services that enables us to transform abstract needs into concrete and effective solutions.
          </p>
        </div>
      </div>
    )
  }
  